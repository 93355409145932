@import url('https://fonts.googleapis.com/css2?family=Montserrat:&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;


  background: rgb(0, 0, 0);
}

input, label{
  color:#fff
}
code {
  font-family: 'Montserrat', sans-serif;
  color: rgb(133, 128, 128);
  
}



span{
  font-family: 'Montserrat', sans-serif;

}


header{
  font-size: 2rem;
}

button{
  font-family: 'Montserrat', sans-serif;

}